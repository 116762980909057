import { useMutation, UseMutationOptions } from 'react-query'

import { apiClient, ApiError } from 'main/services/api/api-client'
import { RunbookRestoreResponse } from '../api/data-providers/runbook-types'

export const useRunbookRestore = ({
  options = {}
}: {
  options: UseMutationOptions<RunbookRestoreResponse, ApiError, { runbookId: number }>
}) => {
  return useMutation<RunbookRestoreResponse, ApiError, { runbookId: number }>(
    'archived-runbook-restore',
    async ({ runbookId }) => {
      const { data } = await apiClient.patch({
        url: `runbooks/${runbookId}/restore`
      })

      return data as RunbookRestoreResponse
    },
    options
  )
}
