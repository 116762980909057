import { forwardRef, memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { eventManager } from 'event-manager'
import queryString from 'query-string'
import { useHotkeys } from 'react-hotkeys-hook'
import { useQueryClient } from 'react-query'
import { Mutation } from 'react-query/types/core/mutation'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { VirtuosoHandle } from 'react-virtuoso'
import styled from 'styled-components'

import {
  Box,
  InfiniteList,
  ListItemSkeletonLoader,
  Message,
  Modal,
  NoResourceFound,
  Text,
  themeEdgeSize,
  useNotify,
  useUpdateEffect
} from '@cutover/react-ui'
import { RunbooksListActionHeader } from './runbooks-list-action-header'
import {
  useCloseRightPanelOnUnmount,
  useRightPanelTypeState,
  useRightPanelTypeValue,
  useSetActiveRightPanelState,
  useToggleRightPanel
} from 'main/components/layout/right-panel'
import { ArchiveRunbooksModal } from '../../modals/archive-runbooks-modal'
import {
  browserQueryStringToServerQueryObject,
  FilterValue,
  serverQueryObjectToBrowserQueryString
} from 'main/components/shared/filter/filter-params'
import {
  useClearFilterParam,
  useClearFilterParams,
  useSetFilterParams
} from 'main/components/shared/filter/filter-provider'
import { MergeRunbooksModal } from 'main/components/workspace/modals/merge-runbooks/merge-runbooks-modal'
import { useHasTemplatesAwaitingReview } from 'main/components/shared/hooks/user'
import { RunbookListItem } from 'main/components/shared/runbook-list-item'
import { mutationCache } from 'main/query-client'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'
import { useInitialMount, useLanguage } from 'main/services/hooks'
import { RunbookListRunbook } from 'main/services/queries/types'
import { usePermissions } from 'main/services/queries/use-permissions'
import { useRunbookRestore } from 'main/services/queries/use-runbook-restore'
import { RUNBOOK_LIST_DEFAULTS } from 'main/services/queries/use-runbooks-infinite'
import { RunbookUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import { useRouting } from 'main/services/routing'
import { DuplicateRunbooksModalWorkspaceWrapper } from 'main/components/workspace/modals/duplicate-runbooks/duplicate-runbooks-modal-workspace-wrapper'
import { ConfigModel } from 'main/data-access'

// TODO: these belong with some layout related constants
const PAGE_HEADER_HEIGHT = 72
const PAGE_SUBHEADER_HEIGHT = 64
const RUNBOOKS_LIST_ACTION_HEADER_HEIGHT = 64
const RUNBOOK_ITEM_HEIGHT = 53 // 52 + 1px for gap

export const RunbooksList = () => {
  const { accountId: accountSlug } = useParams<{ accountId?: string }>()
  const location = useLocation()
  const filters = browserQueryStringToServerQueryObject({ query: location.search })
  const [searchParams] = useSearchParams()
  const templateType = searchParams.get('template_type') || undefined

  return (
    <>
      {accountSlug ? (
        <RunbooksListContent
          key={`${accountSlug}-${templateType ?? 'runbooks'}`}
          accountSlug={accountSlug}
          templateType={templateType}
          filters={filters}
        />
      ) : null}
    </>
  )
}

type RunbooksListContentProps = {
  accountSlug: string
  filters: Record<string, FilterValue>
  templateType: string | undefined
}

export const RunbooksListContent = memo(({ accountSlug, filters, templateType }: RunbooksListContentProps) => {
  const isInitialMount = useInitialMount()
  useCloseRightPanelOnUnmount()
  const permissions = usePermissions('runbooks') // TODO: this is causing re-renders
  const canCreateOrDuplicate = permissions('create')
  const { openRightPanel } = useSetActiveRightPanelState()
  const [{ runbookId: editingRunbookId }, { closeRightPanel }] = useRightPanelTypeState('runbook-edit')
  const isEditingSingleRunbook = !!editingRunbookId
  const { t } = useLanguage('runbooks', { keyPrefix: 'list' })
  const [isSelectMode, setIsSelectMode] = useState(false)
  const [selectedRunbookIds, setSelectedRunbookIds] = useState<number[]>([])
  const [lastSelectedId, setLastSelectedId] = useState<number | null>(null)
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const listRef = useRef<VirtuosoHandle>(null)
  const isArchiveMode = !!searchParams.get('archived')
  const sortBy = filters?.sort_by || 'updated_at'
  const hasTemplatesAwaitingReview = useHasTemplatesAwaitingReview()
  const [showArchiveRunbooksModal, setShowArchiveRunbooksModal] = useState(false)
  const [showMergeRunbooksModal, setShowMergeRunbooksModal] = useState(false)
  const [runbookToArchive, setRunbookToArchive] = useState<number>()
  const [runbookToDuplicate, setRunbookToDuplicate] = useState<{ id: number }>()
  const [showDuplicateRunbooksModal, setShowDuplicateRunbooksModal] = useState(false)
  const { isFetchingNextPage, runbooks, runbooksMeta, hasNextPage, fetchNextPage, isLoading } = useWorkspaceData()
  const runbookIds = runbooks?.map(runbook => runbook.id) || []

  const clearFilterParam = useClearFilterParam()
  const clearFilterParams = useClearFilterParams()
  const setFilterParams = useSetFilterParams()

  const mutationCacheUnsubscribe = useRef<(() => void) | null>(null)

  useEffect(() => {
    // unsubscribe from any existing mutation cache subscription
    mutationCacheUnsubscribe.current?.()

    // only subscribe so that successful runbook mutations trigger scroll to top if viewing by last updated (for now).
    if (sortBy === 'updated_at') {
      // RQ doesn't expose a type for this function, though the docs explain how to use it.
      const handleUpdatedRunbook: any = (
        mutation: Mutation<RunbookUpdateResponse, unknown, { runbook?: RunbookListRunbook }>
      ) => {
        if (mutation.state.status === 'success' && mutation.state.data?.meta?.headers?.request_method === 'update') {
          listRef.current?.scrollToIndex(0)
        }
      }

      mutationCacheUnsubscribe.current = mutationCache.subscribe(handleUpdatedRunbook)
    }

    // scroll to the top when switching between sortBys, unless it's the initial mount where navigating
    // back to the list is desired to be at the same scroll position.
    if (!isInitialMount) listRef.current?.scrollToIndex(0)

    return () => mutationCacheUnsubscribe.current?.()
  }, [sortBy])

  useUpdateEffect(() => {
    closeRightPanel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateType])

  const handleClickBulkEdit = useCallback(() => {
    openRightPanel({
      type: 'runbooks-bulk-edit',
      runbookIds: selectedRunbookIds,
      onClose: () => setSelectedRunbookIds([])
    })
  }, [openRightPanel, selectedRunbookIds])

  const handleItemOnSelect = useCallback(
    (id: number) => {
      // lastSelectedId only exists when shift is pressed and one or several runbooks are selected,
      // so if there is lastSelectedId on item select, it means that user selected a range of runbooks with shift.
      if (lastSelectedId !== null && id !== lastSelectedId) {
        const selectedIdIndex = runbookIds?.indexOf(id)
        const lastSelectedIdIndex = runbookIds?.indexOf(lastSelectedId)

        const rangeIds =
          lastSelectedIdIndex < selectedIdIndex
            ? runbookIds.slice(lastSelectedIdIndex + 1, selectedIdIndex + 1)
            : runbookIds.slice(selectedIdIndex, lastSelectedIdIndex).reverse()

        setSelectedRunbookIds(ids => {
          const newSelectedRunbookIds = [...ids]

          rangeIds.forEach(id => {
            if (!selectedRunbookIds.includes(id)) {
              newSelectedRunbookIds.push(id)
            }
          })

          return newSelectedRunbookIds
        })
      } else {
        setSelectedRunbookIds(ids => {
          return ids.includes(id) ? ids.filter(selectedId => selectedId !== id) : [...ids, id]
        })
      }
      setLastSelectedId(null)
    },
    [lastSelectedId, selectedRunbookIds, runbookIds]
  )

  const handleOnClickSelectAll = useCallback(() => {
    if (!selectedRunbookIds.length) {
      setSelectedRunbookIds(runbooksMeta?.all_filtered_runbook_ids || [])
      setIsSelectMode(true)
    } else {
      setSelectedRunbookIds([])
      setIsSelectMode(false)
    }
  }, [selectedRunbookIds, runbooksMeta])

  const handleClearAll = () => {
    clearFilterParams({ newFilters: { template_type: filters?.template_type || [], archived: isArchiveMode } })
  }
  useEffect(() => {
    // if deselecting while bulk edit panel is open
    if (!selectedRunbookIds.length) {
      closeRightPanel()
      setIsSelectMode(false)
    }
    // if selecting a runbook while editing a single runbook
    else if (isEditingSingleRunbook) {
      setIsSelectMode(true)
      openRightPanel({
        type: 'runbooks-bulk-edit',
        runbookIds: selectedRunbookIds,
        onClose: () => setSelectedRunbookIds([])
      })
    } else {
      setIsSelectMode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRunbookIds])

  // list item callbacks
  const handleClickArchive = useCallback(
    (id: number) => {
      setRunbookToArchive(id)
      setShowArchiveRunbooksModal(true)
    },
    [setShowArchiveRunbooksModal, runbookToArchive]
  )

  const handleClickDuplicate = useCallback(
    (runbookId: number) => {
      setRunbookToDuplicate({ id: runbookId })
      setShowDuplicateRunbooksModal(true)
    },
    [setShowDuplicateRunbooksModal]
  )

  const handleClickCreate = useCallback(() => eventManager.emit('open-create-runbook-modal'), [])

  const handleClickMerge = useCallback(() => {
    setShowMergeRunbooksModal(true)
    //eventManager.emit('open-angular-merge-runbooks-modal', { runbookIds: selectedRunbookIds })
  }, [selectedRunbookIds])

  const handleClickBulkDuplicate = useCallback(() => {
    setShowDuplicateRunbooksModal(true)
  }, [setShowDuplicateRunbooksModal, selectedRunbookIds])

  const reload = () => {
    queryClient.refetchQueries([accountSlug, 'runbooks', { ...RUNBOOK_LIST_DEFAULTS, ...filters }])
    setSelectedRunbookIds([])
    setIsSelectMode(false)
  }

  useEffect(() => {
    eventManager.on('reload-runbook-list', reload)
    return () => eventManager.off('reload-runbook-list', reload)
  }, [])

  const handleClickBulkArchive = useCallback(() => {
    setRunbookToArchive(undefined)
    setShowArchiveRunbooksModal(true)
  }, [setShowArchiveRunbooksModal, selectedRunbookIds])

  // Keyboard shortcuts --------------------------------------------------- //
  // create runbook
  useHotkeys('ctrl+shift+n', handleClickCreate, { enabled: !isSelectMode }, [isSelectMode])
  // archive runbook
  useHotkeys('backspace', handleClickBulkArchive, { enabled: isSelectMode, preventDefault: true }, [isSelectMode])
  // edit runbook
  useHotkeys('meta+e', handleClickBulkEdit, { enabled: isSelectMode, preventDefault: true }, [
    isSelectMode,
    handleClickBulkEdit
  ])
  // duplicate runbook
  useHotkeys('meta+u', handleClickBulkDuplicate, { enabled: isSelectMode, preventDefault: true }, [isSelectMode])
  // select all
  useHotkeys(
    'meta+a',
    () => {
      if (runbooksMeta?.all_filtered_runbook_ids && !selectedRunbookIds.length && !isSelectMode) {
        setSelectedRunbookIds(runbooksMeta.all_filtered_runbook_ids)
        setIsSelectMode(true)
      }
    },
    { preventDefault: true },
    [runbooksMeta, selectedRunbookIds, isSelectMode]
  )
  // set last selected id on shift click
  useHotkeys(
    'shift',
    () => {
      const lastSelectedId = selectedRunbookIds?.length > 0 ? selectedRunbookIds[selectedRunbookIds?.length - 1] : null
      setLastSelectedId(lastSelectedId)
    },
    { enabled: isSelectMode, keydown: true },
    [isSelectMode, selectedRunbookIds]
  )
  useHotkeys('shift', () => setLastSelectedId(null), { enabled: isSelectMode, keyup: true }, [isSelectMode])
  // deselect all
  useHotkeys(
    'meta+d',
    () => {
      if (isSelectMode) {
        setSelectedRunbookIds([])
        setIsSelectMode(false)
      }
    },
    { preventDefault: true },
    [isSelectMode]
  )
  // merge runbooks
  useHotkeys(
    'meta+g',
    handleClickMerge,
    { enabled: isSelectMode && selectedRunbookIds.length > 1, preventDefault: true },
    [isSelectMode, selectedRunbookIds]
  )
  // show archived / show unarchived
  useHotkeys(
    'ctrl+shift+v',
    () => {
      if (isArchiveMode) {
        clearFilterParam('archived')
      } else {
        setFilterParams({ archived: 1 })
      }
    },
    { preventDefault: true },
    [isArchiveMode]
  )

  const filteredTemplatesToReviewLink = useMemo(() => {
    const params = serverQueryObjectToBrowserQueryString({
      queryObject: { ...filters, awaiting_my_review: 1 }
    })
    const [path] = location.href.split('?')
    return `${path}${params}`
  }, [location])

  const viewUnarchivedLink = useMemo(() => {
    const params = serverQueryObjectToBrowserQueryString({
      queryObject: filters,
      excludeKeys: ['archived']
    })

    const [path] = location.href.split('?')
    return `${path}${params}`
  }, [filters, location])

  return (
    <>
      <Box direction="column" fill="vertical">
        {isArchiveMode ? (
          <Box
            data-testid="archiveResults-text"
            margin={{ top: 'medium', bottom: 'medium' }}
            pad={{ right: 'medium' }}
            fill="horizontal"
          >
            <Message message={t('archivedView.currentlyViewing.archivedResult', { link: viewUnarchivedLink })} />
          </Box>
        ) : !isLoading && !runbooks?.length ? null : (
          <>
            {/* the main container doesn't provide spacing on the right. Tables for example may horizontally scroll so its inner content needs
            to provide the spacing. */}
            <Box pad={{ right: 'medium' }} flex={false}>
              <RunbooksListActionHeader
                selectedRunbooksCount={selectedRunbookIds.length}
                onClickEdit={handleClickBulkEdit}
                onClickDuplicate={canCreateOrDuplicate ? handleClickBulkDuplicate : undefined}
                onClickMerge={handleClickMerge}
                onClickArchive={handleClickBulkArchive}
                isSelectMode={isSelectMode}
                disableMergeAction={templateType === 'snippet'}
                onClickSelectOrDeselectAll={handleOnClickSelectAll}
                allRunbooksSelected={selectedRunbookIds.length === runbooksMeta?.all_filtered_runbook_ids?.length}
              />
              {templateType === 'default' && hasTemplatesAwaitingReview && (
                <Box margin={{ bottom: 'medium' }} fill="horizontal" flex={false}>
                  <Message
                    message={t('awaitingTemplateReview.message', { link: filteredTemplatesToReviewLink })}
                    type="error"
                  />
                </Box>
              )}
            </Box>
          </>
        )}
        {isLoading ? (
          Array.from({ length: 3 }).map((_el, i) => <ListItemSkeletonLoader key={i} sizeIndex={i} />)
        ) : !runbooks?.length ? (
          <NoResourceFound
            context={templateType === 'default' ? 'template' : templateType === 'snippet' ? 'snippet' : 'runbook'}
            clearAllFilters={handleClearAll}
          />
        ) : (
          <MemoizedRunbooksList
            ref={listRef}
            runbooks={runbooks}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            selectedRunbookIds={selectedRunbookIds}
            isSelectMode={isSelectMode}
            onSelect={handleItemOnSelect}
            onClickDuplicate={canCreateOrDuplicate ? handleClickDuplicate : undefined}
            onClickArchive={handleClickArchive}
            onClickCreate={handleClickCreate}
            templateType={templateType}
          />
        )}
      </Box>
      {showDuplicateRunbooksModal && (runbookToDuplicate || selectedRunbookIds.length > 0) && (
        <DuplicateRunbooksModalWorkspaceWrapper
          runbook={runbookToDuplicate}
          runbookIds={selectedRunbookIds.length > 0 ? selectedRunbookIds : undefined}
          open={showDuplicateRunbooksModal}
          closeModal={() => {
            setRunbookToDuplicate(undefined)
            setShowDuplicateRunbooksModal(false)
          }}
          templateType={templateType}
          reload={reload}
        />
      )}

      <MergeRunbooksModal
        open={showMergeRunbooksModal}
        closeModal={() => setShowMergeRunbooksModal(false)}
        runbookIds={selectedRunbookIds}
        canCreateRunbooks={canCreateOrDuplicate}
      />

      {showArchiveRunbooksModal && (
        <ArchiveRunbooksModal
          selectedRunbookIds={runbookToArchive ? [runbookToArchive] : selectedRunbookIds}
          templateType={templateType}
          onClose={() => setShowArchiveRunbooksModal(false)}
          onSuccess={reload}
        />
      )}
    </>
  )
})

const createListRenderLoader = (count: number = 5) => {
  return () => {
    return (
      <>
        {Array.from({ length: count }).map((_el, i) => (
          <ListItemSkeletonLoader key={i} sizeIndex={i} />
        ))}
      </>
    )
  }
}

type RunbooksListProps = {
  onSelect: (id: number) => void
  onClickArchive: (id: number) => void
  onClickDuplicate?: (id: number) => void
  onClickCreate: (id: number) => void
  selectedRunbookIds: number[]
  isSelectMode: boolean
  hasNextPage: boolean | undefined
  runbooks: RunbookListRunbook[]
  isFetchingNextPage: boolean | undefined
  fetchNextPage: (() => Promise<any>) | undefined
  templateType: string | undefined
}

const MemoizedRunbooksList = memo(
  forwardRef<VirtuosoHandle, RunbooksListProps>(
    (
      {
        onSelect,
        onClickDuplicate,
        onClickArchive,
        selectedRunbookIds,
        isSelectMode,
        hasNextPage,
        runbooks,
        isFetchingNextPage,
        fetchNextPage,
        templateType
      },
      ref
    ) => {
      const toggleRunbookEditPanelFn = useToggleRightPanel('runbook-edit')
      const { runbookIds: editingRunbookIds } = useRightPanelTypeValue('runbooks-bulk-edit')
      const { runbookId: editingRunbookId } = useRightPanelTypeValue('runbook-edit')
      const handleSelect = useCallback(
        (id: number) => {
          if (id === editingRunbookId) toggleRunbookEditPanelFn(false)
          else onSelect(id)
        },
        [editingRunbookId, onSelect, toggleRunbookEditPanelFn]
      )
      const handleEdit = useCallback(
        (id: number) => {
          toggleRunbookEditPanelFn({ runbookId: id })
        },
        [toggleRunbookEditPanelFn]
      )
      const [isArchiveModalOpen, setArchiveModalOpen] = useState(false)
      const { accountId: accountSlug } = useParams<{ accountId: string }>()
      const queryClient = useQueryClient()
      const location = useLocation()
      const navigate = useNavigate()
      const { t } = useLanguage('runbooks', { keyPrefix: 'list' })
      const notify = useNotify()
      const { toRunbook, toWorkspace } = useRouting()
      const { height } = useWindowSize()
      const scrollSize = height - PAGE_HEADER_HEIGHT - PAGE_SUBHEADER_HEIGHT - RUNBOOKS_LIST_ACTION_HEADER_HEIGHT
      const loaderItemCount = Math.floor(scrollSize / RUNBOOK_ITEM_HEIGHT)
      const readOnlyFFEnabled = ConfigModel.useIsFeatureEnabled('read_only_archived_runbooks')

      const { mutate: restoreRunbook } = useRunbookRestore({
        options: {
          onSuccess: () => {
            notify.success(t('listItem.notification.runbookRestored'))
            queryClient.invalidateQueries([accountSlug, 'runbooks'])
            accountSlug && navigate(toWorkspace({ accountSlug, templateType }))
          }
        }
      })

      const createLinkTo = useCallback(
        (id: number) => toRunbook({ accountSlug: accountSlug as string, runbookId: id }),
        [accountSlug]
      )

      const handleClickRestore = useCallback((runbookId: number) => restoreRunbook({ runbookId }), [])

      const handleClickItem = useCallback(
        (item: RunbookListRunbook) => {
          if (item.archived && !readOnlyFFEnabled) {
            setArchiveModalOpen(true)
          } else {
            let searchObject = queryString.parse(location.search)
            localStorage.setItem('previousQuery', JSON.stringify(searchObject))
          }
        },
        [location.search]
      )

      const tabLabelledBy =
        templateType === 'default' ? 'tab-templates' : templateType === 'snippet' ? 'tab-snippets' : 'tab-runbooks'

      return (
        <RunbooksListWrapper
          id="tabpanel-runbooks"
          aria-labelledby={`${tabLabelledBy} view-toggle-list`}
          // quite brittle but allows to to get the scrollbar not obscure the list content
          css={`
            > div > div {
              padding-right: ${themeEdgeSize('medium')};
            }
          `}
        >
          <Modal
            title={t('archiveModal.title')}
            open={isArchiveModalOpen}
            hasCancelButton={false}
            confirmIcon="chevron-right"
            confirmText={t('archiveModal.confirmText')}
            onClickConfirm={() => setArchiveModalOpen(false)}
            onClose={() => setArchiveModalOpen(false)}
          >
            <Text>{t('archiveModal.description')}</Text>
          </Modal>
          <InfiniteList<RunbookListRunbook>
            setRef={ref as RefObject<VirtuosoHandle>}
            items={runbooks}
            role="grid"
            tabIndex={-1}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            defaultItemHeight={53}
            fixedItemHeight={53}
            increaseViewportBy={scrollSize}
            height={scrollSize + 'px'}
            renderLoader={createListRenderLoader(loaderItemCount)}
            renderItem={(index, item) => {
              return (
                <RunbookListItem
                  rowIndex={index}
                  role="row"
                  withActions
                  withSelect
                  key={item.id}
                  runbook={item}
                  editing={item.id === editingRunbookId || editingRunbookIds?.includes(item.id)}
                  onSelect={handleSelect}
                  onClickItem={handleClickItem}
                  onClickEdit={handleEdit}
                  onClickDuplicate={onClickDuplicate}
                  onClickArchive={onClickArchive}
                  onClickRestore={handleClickRestore}
                  selectedByBulkAction={selectedRunbookIds.includes(item.id)}
                  isSelectMode={isSelectMode}
                  createLinkTo={createLinkTo}
                />
              )
            }}
          />
        </RunbooksListWrapper>
      )
    }
  )
)

const RunbooksListWrapper = styled(Box).attrs(() => ({
  overflow: 'auto',
  responsive: false,
  height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px - ${PAGE_SUBHEADER_HEIGHT}px - ${RUNBOOKS_LIST_ACTION_HEADER_HEIGHT}px)`
}))`
  line-height: normal !important;
`
